.sidebar {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	box-sizing: content-box;
	gap: 7%;
	min-height: 50px;
	background-color: #ffffff75;
	-webkit-overflow-scrolling: touch;
	overflow-x: auto;
	scroll-behavior: smooth;
	width: 100%;
}

.sticky-top {
	position: -webkit-sticky; /* Pour le support Safari */
	position: sticky;
	top: 0;
	z-index: 10; /* z-index élevé pour rester au-dessus des autres éléments, ajustez selon votre besoin */
}

.sidebar::-webkit-scrollbar {
	width: 10px;
	height: 4px;
	top: 3px;
	background-color: #f5f5f5;
}

.sidebar::-webkit-scrollbar-thumb {
	background-color: #ccc;
	border-radius: 19px;
}

.sidebar::-webkit-scrollbar-track {
	background-color: #f5f5f5;
}

.sidebar::-webkit-scrollbar-button {
	width: 0;
	height: 0;
}

.nav-link {
	position: relative;
	text-decoration: none;
	letter-spacing: 0.0892857143em;
	font-size: 1.2rem;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	text-align: center;
}

.nav-link.active {
	color: #d965c6;
	cursor: default;
	background-color: #ffffff75;
	padding: 0 30px;
	border-radius: 8px 8px 0 0;
}

.nav-link:last-child {
	padding-right: 3%;
}

.nav-link:first-child {
	padding-left: 3dvw;
}

.sidebar-container {
	display: flex;
	flex-direction: row;
	overflow-x: auto;
	margin: auto;
	width: 100%;
	/* background-color: #233748; */
}

.left-arrow, .right-arrow {
	width: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	z-index: 10;
	border: 1px solid #e1e1e1;
	opacity: 0.5;
}

.left-arrow {
	left: 0;
}

.right-arrow {
	right: 0;
}

@media screen and (max-width: 600px) {
	.nav-link {
		font-size: .71rem;
	}

	.left-arrow, .right-arrow {
		width: 23px;
	}
}

@media screen and (max-width: 800px) {
	.nav-link:first-child {
		padding-left: 10%;
	}
}