/* Home.css */

body {
    background-color: #000000c2;
    font-family: "Nunito-Black";
    margin: 0;
    overflow-x: hidden;
}

.app {
    overflow: hidden;
}

body.no-scroll {
    overflow: hidden;
}

.menu-h1 {
    font-size: 3em;
    text-align: center;
    margin: 0;
    padding: 0;
    color: #e1e1e1;
    font-weight: 700;
    margin-bottom: 15px;
    border-radius: 8px;
    width: 45%;
}

.menu-title-container {
    display: flex;
    justify-content: center;
}

.MenuTitle {
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    font-size: 3em;
    gap: 11%;
    margin: 10 2.6% 0 2.6%;
}

.MenuTitle h2 {
    color: #FFB3CC;
    background: #2626287a;
    border-radius: 8px;
    margin-top: 38px;
}

.main-container {
    margin: auto;
    border-radius: 8px;
}

.main-container::-webkit-scrollbar {
    width: 0em;
}

.section {
    scroll-snap-align: start;
    padding: 0;
    height: calc(100% - 110px);
    min-height: 550px;
}

.section.menu {
    padding: 0 20px 20px 20px;
    height: 100dvh;
    display: flex;
    flex-direction: column;
}

.section.home {
    font-family: 'Montserrat', sans-serif;
    margin: auto;
    position: relative;
    overflow: hidden;
}

body.home .background {
    filter: none;
}

.content {
    position: relative;
    z-index: 1;
    padding-top: 60px;
    height: 550px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.background {
    content: "";
    position: fixed; /* ou relative */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    opacity: 0.8;
    z-index: -1;
    /* animation: slideshow 30s infinite; */
    filter: blur(6px); /* Tester la suppression du flou */
    will-change: background-image; /* Pour améliorer la performance */
}

@keyframes slideshow {
    0% {
        background-image: url("/public/Jap/sumo-kung-2.jpg");
        transform: scale(1);
    }

    24.99% {
        background-image: url("/public/Jap/sumo-kung-2.jpg");
        transform: scale(1.3);
    }
    25% {
        background-image: url("/public/Jap/P6-1.jpg");
        transform: scale(1);
    }
    49.99% {
        background-image: url("/public/Jap/P6-1.jpg");
        transform: scale(1.3);
    }
    50% {
        background-image: url("/public/Thai/mi-prat-1.jpg");
        transform: scale(1);
    }
    74.99% {
        background-image: url("/public/Thai/mi-prat-1.jpg");
        transform: scale(1.3);
    }
    75% {
        background-image: url("/public/Thai/chicken-dynamite-2.jpg");
        transform: scale(1);
    }
    99.99% {
        background-image: url("/public/Thai/chicken-dynamite-2.jpg");
        transform: scale(1.3);
    }
    100% {
        background-image: url("/public/Jap/poke-crevette.jpg");
        transform: scale(1);
    }
}

.deco {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.deco-img {
    min-width: 180px;
    width: 10%;
    margin: 0 0 200px 0;
    filter: drop-shadow(0 4px 9px rgba(61, 32, 204, 0.5));
}

@keyframes slide-up {
    0% {
        transform: translateY(35px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.phrase-container {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: white;
    text-align: center;
}

.impact-phrase {
    letter-spacing: 3px;
    font-family: 'Nunito-Black';
    width: 50%;
    backdrop-filter: blur(3px);
    background-color: #2626287a;
    margin: auto;
    padding: 20px;
    border-radius: 10px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
    line-height: 1.2;
    font-size: 3rem;

    /* Animation */
    opacity: 0;
    /* Start invisible */
    transform: scale(0.8) translateY(-20px);
    /* Start smaller and slightly above */
    animation: zoomInBounce 1s forwards;
    /* Trigger animation */
}

@keyframes zoomInBounce {
    0% {
        opacity: 0;
        transform: scale(0.8) translateY(-50px);
    }

    50% {
        opacity: 1;
        transform: scale(1);
        /* Scale to normal size */
        transform: translateY(0px);
        /* Move to original position */
    }

    70% {
        opacity: 1;
        transform: translateY(-15px);
        /* Bounce effect down */
    }

    100% {
        opacity: 1;
        transform: translateY(0);
        /* Final position */
    }
}

.slider {
    opacity: 0;
    /* Masquer l'élément par défaut */
    transition: opacity 0.5s ease;
    /* Transition pour l'apparition */
}

.slider.visible {
    opacity: 1;
    /* Rendre visible quand la classe 'visible' est ajoutée */
    animation: slide-up 0.5s ease forwards;
    /* Jouer l'animation */
}

.welcome {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    padding: 0;
    color: #e1e1e1;
    font-weight: 700;
    margin-top: 140px;
    margin-bottom: 0;
}

.text {
    padding: 0 10px;
    backdrop-filter: blur(3px);
    letter-spacing: 15px;
    background: linear-gradient(90deg, #D94878, #3B746D, #074C48, #D94878);
    background-size: 300%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: wave 3s infinite alternate;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.flag-svg {
    height: 3rem;
}

@keyframes wave {
    0% {
        background-position: 0% 100%;
    }

    100% {
        background-position: 100% 0%;
    }
}

.menu-link-pdf {
    font-size: 2.2rem;
    color: #e70b96;
    /* text-decoration: none; */
}

.menu-pdf {
    font-size: 2rem;
    display: flex;
    width: 45%;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: black;
}

.scrollbar {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 1rem;
    animation: bounce 1s infinite;
    /* Ajout de l'animation */
}

.down-arrow {
    height: 105px;
}

img {
    cursor: pointer;
}

@keyframes bounce {

    0%,
    100% {
        transform: translateY(0);
        /* Position initiale */
    }

    50% {
        transform: translateY(10px);
        /* Déplacement vers le bas */
    }
}

@media screen and (max-width: 1400px) {
    .deco-img {
        margin: 0 0 50px 0;
    }
}

@media screen and (max-width: 1000px) {
    .contact-button {
        bottom: 28px;
    }

    .sub-menu {
        flex-wrap: wrap;
        flex-direction: column;
    }

    .menu-h1 {
        font-size: 2.5em;
        width: 100%;
    }

    .MenuTitle {
        font-size: 2.5em;
        gap: 5%;
    }

    .menu-pdf {
        font-size: 1rem;
    }

    .menu-link-pdf {
        font-size: 1.2rem;
    }

    .delivery-section {
        width: 100%;
    }

    .contact-button {
        right: auto;
        font-size: 1.5rem;
    }

    .contact-us {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .partner img {
        flex-wrap: wrap;
        width: 3rem;
        justify-content: space-evenly;
    }

    .impact-phrase {
        font-size: 2rem;
    }

    .flag-svg {
        height: 2rem;
    }
}

@media screen and (max-width: 800px) {
    .content {
        justify-content: flex-start;
    }

    .MenuTitle h2 {
        font-size: 2rem;
    }

    .deco-img {
        margin: 0;
    }

    .impact-phrase h1 {
        font-size: 1.4rem;
    }

    .impact-phrase {
        width: 80%;
    }
}

@media screen and (max-width: 700px) {
    .sub-menu {
        flex-wrap: wrap;
        flex-direction: column;
    }

    .delivery {

        font-size: 1.13rem;
        margin-bottom: 5px;
    }

    .section {
        height: auto;
    }

    .section.menu {
        height: auto;
    }

    .text {
        font-size: 1.5rem;
        letter-spacing: 8px;
    }

    .content {
        padding-top: 0;
    }

    .section {
        height: calc(100% - 80px);
    }

    .section.home {
        justify-content: center;
    }

    .impact-phrase {
        font-size: 1.52rem;
    }

    .flag-svg {
        height: 1.5rem;
    }

    .welcome {
        font-size: 1rem;
    }

    .deco-img {
        width: 20%;
    }

    .menu-pdf {
        width: 100%;
        font-size: 1.2rem;
    }

    .menu-link-pdf {
        font-size: 1.4rem;
    }

    .delivery-section {
        width: 100%;
    }

    .contact-button {
        right: auto;
        font-size: 1.5rem;
        bottom: 20px;
    }

    .contact-us {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .partner img {
        flex-wrap: wrap;
        width: 3rem;
        justify-content: space-evenly;
    }

    .partners {
        gap: 30px;
    }
}