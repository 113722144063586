/* Topbar.css */
.topbar {
    font-family: "Nunito-Black";
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #021211;
    /* box-shadow: 0 1px rgba(255, 255, 255, 0.24); */
    backdrop-filter: blur(5px);
    z-index: 99;
    position: sticky;
}

.topbar.isOpen {
    display: none;
}

.close-button:hover {
    transform: scale(1.2);
}

.cart-redirect {
    margin-bottom: 25px;
}

.close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    font-size: 2rem;
    color: white;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.logo-menu img {
    width: 110px;
    scale: 1.8;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.logo-menu img:hover {
    transform: scale(1.2);
    /* Agrandir légèrement au survol */
}

.menu-box {
    /* position: fixed; */
    top: 0;
    left: 0;
    /* height: calc(100vh - 80px); */
    height: 100vh;
    background-color: #FFB3CCcc;
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    z-index: 100;
}

.cart-button-redirect {
    position: relative;
    background: linear-gradient(135deg, #ff7eb3, #ff758c);
    color: white;
    font-size: 2rem;
    font-weight: bold;
    padding: 12px 4rem;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    box-shadow: 0 4px 15px rgba(255, 117, 140, 0.3);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    outline: none;
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 10px rgba(255, 117, 140, 0.5);
    }

    50% {
        box-shadow: 0 0 20px rgba(255, 117, 140, 0.8);
    }

    100% {
        box-shadow: 0 0 10px rgba(255, 117, 140, 0.5);
    }
}

.cart-button-redirect:active {
    transform: scale(0.95);
    box-shadow: 0 2px 10px rgba(255, 117, 140, 0.3);
}


.menu-deroulant {
    display: none;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    z-index: 99;
    right: 25px;
    position: sticky;
}

.logo img {
    width: 110px;
    scale: 1.3;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.3s ease;
    /* margin-left: 35px; */
}

.logo img:hover {
    transform: scale(1.2);
    /* Agrandir légèrement au survol */
}

.menu-links {
    display: flex;
    gap: 5rem;
    /* margin-bottom: 90px; */
}

.menu-links a {
    text-decoration: none;
    color: white;
    font-weight: bold;
    font-size: 1.2rem;
    position: relative;
    margin: auto;
    transition: color 0.3s ease;
}

.menu-links a::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #e70b96;
    transition: width 0.4s ease;
    position: absolute;
    left: 50%;
    bottom: -5px;
    transform: translateX(-50%);
    transform-origin: center;
    /* Point de départ au centre */
}

.menu-links a:hover::after {
    width: 100%;
    /* Effet de soulignement */
}

.menu-links a:hover {
    color: #e70b96;
}

.menu-links {
    display: flex;
    gap: 5rem;
}

.menu-links a {
    text-decoration: none;
    color: white;
    font-weight: bold;
    font-size: 1.2rem;
    position: relative;
    transition: color 0.3s ease;
}

.menu-links a::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #e70b96;
    transition: width 0.4s ease;
    position: absolute;
    left: 50%;
    bottom: -5px;
    transform: translateX(-50%);
    transform-origin: center;
    /* Point de départ au centre */
}

.menu-links a:hover::after {
    width: 100%;
    /* Effet de soulignement */
}

.menu-links a:hover {
    color: #e70b96;
}

.navigation-links {
    display: flex;
    gap: 5rem;
}

.navigation-links a {
    text-decoration: none;
    color: white;
    font-weight: bold;
    font-size: 1.2rem;
    position: relative;
    transition: color 0.3s ease;
}

.navigation-links a::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #e70b96;
    transition: width 0.4s ease;
    position: absolute;
    left: 50%;
    bottom: -5px;
    transform: translateX(-50%);
    transform-origin: center;
    /* Point de départ au centre */
}

.navigation-links a:hover::after {
    width: 100%;
    /* Effet de soulignement */
}

.navigation-links a:hover {
    color: #e70b96;
}

.order-button {
    background-color: #d60b76;
    font-size: 1.35rem;
    color: #fff;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 20px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.order-button:hover {
    background-color: #ff007f;
    transform: scale(1.05);
    /* Légère expansion au survol */
}

.menu-links {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.menu-links a {
    font-size: 2rem;
    color: white;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
}

@media screen and (max-width: 890px) {
    .navigation-links {
        width: 100%;
        justify-content: space-evenly;
        gap: 1.82rem;
    }

    .topbar img {
        width: 80px;
    }

    .topbar {
        height: 105px;
    }

    .navigation-links a {
        font-size: 2.3dvw;
    }

    .order-button {
        display: none;
    }

}

@media screen and (max-width: 600px) {
    .topbar {
        height: 80px;
        justify-content: space-between;
    }

    .navigation-links {
        display: none;
    }

    /* .menu-box  */
    .menu-deroulant {
        display: contents;
    }

    .menu-deroulant img {
        width: 32px;
        margin-right: 40px;
        cursor: pointer;
    }

    .navigation-links a {
        display: block;
        padding: 10px 20px;
        text-align: center;
    }

    .logo img {
        margin-left: 35px;
    }
}