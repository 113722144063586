.cart-popup-container {
    position: fixed;
    right: 0;
    top: 100px;
    width: 350px;
    height: calc(100% - 100px);
    background-color: #ffd4e585;
    z-index: 12;
    border-radius: 8px;
    margin: 12px;
    display: flex;
    flex-direction: column;
    column-gap: 3px;
    align-items: center;
    overflow-y: scroll;
    backdrop-filter: blur(15px);
}

.cart-button {
	width: 12%;
    min-width: 44px;
	background-color: #233748;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.item-quantity-badge {
    /* background-color: #db3ad6; */
    border-radius: 12px;
    /* color: white; */
    padding: 0.2em 0.6em;
    font-size: 0.8em;
    /* margin-left: 0.5em;  */
}

.cart-button img {
	height: 32px;
}

.quantity-control {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #ffd4e5;
    font-size: 1.5rem;
    border-radius: 10px;
    background-color: #d60b76;
    padding: 3px;
}

.close-cart {
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 2rem;
    color: #d60b76;
    position: absolute;
}

.cart-sub-title {
    font-size: 1.5rem;
    margin: 25px;
}

.teext {
    font-size: 1.5rem;
    margin: auto;
}

.cart-item {
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    /* background-color: #d60b77; */
    margin: 10px;
    border-radius: 4px;
    /* border-bottom: 1px solid #d60b76; */
}

.content-cart-details {
    display: flex;
}

.item-price-popup {
    margin: auto 0;
}

.add-cart {
    width: 2.3rem;
    position: relative;
    right: 0;
    top: 0;
}

.add-container {
    cursor: pointer;
    position: absolute;
    bottom: 8%;
    left: 5%;
}

.item-details {
    margin-left: 15px;
    width: 100%;
}

.item-details h3 {
    font-size: 1.2rem;
    margin-bottom: 8px;
}

.item-image.cart-image {
    width: 45%;
}

.remove-item {
    cursor: pointer;
    font-size: 1.5rem;
    color: #d60b76;
    margin-left: auto;
    display: flex;
}

.cart-nb-items {
    width: 1rem;
    height: 1rem;
    position: absolute;
    transform: translate(70%, 70%);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #d60b76;
}

.cart-price {
    font-size: 1.2rem;
    margin-left: auto;
    margin-top: auto;
}

@media screen and (max-width: 700px) {
    .cart-popup-container {
        top: 80px;
    }
}