.compose-ton-roll-container {
  display: flex;
  width: 100%;
  min-height: 49dvh;
  padding: 0 20px;
  border-radius: 10px;
  justify-content: space-between;
  flex-direction: column;
}

.compose-ton-roll-container h3 {
  margin: 20px auto;
  color : white;
}

.footer-compose h1 {
  color: #d60b76
}
.compose-image-details {
  color: #FFFFFF;
}

.category {
  width: calc(20% - 20px);
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  background-color: #d60b76;
  color: #e1e1e1;
  margin-top: 20px;
  overflow-y: scroll;
}

.category::-webkit-scrollbar {
	width: 10px;
	height: 4px;
	top: 3px;
}

.category::-webkit-scrollbar-thumb {
	background-color: #ccc;
	border-radius: 19px;
  width: 3px;
}

.category::-webkit-scrollbar-button {
	width: 0;
	height: 0;
}

.compose-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #FFFFFF;
}

.compose-sub {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.footer-compose {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}

.add-to-cart {
  width: 80px;
  height: 50px;
  background-color: #233748;
  cursor: pointer;
  border-radius: 8px;
}

/* Titre de la catégorie */
.category h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

/* Liste des éléments */
.bullet-point ul {
  /* list-style-type: none; */
  padding: 0 0 0 28px;
}

/* Élément de la liste */
.bullet-point ul li {
  font-size: 18px;
  margin-bottom: 5px;
  color: #233748;
}

.category:nth-child(even) {
  background-color: #233748;
}

.category:nth-child(even) h1 {
  color: #d60b76;
}

.category:nth-child(even) ul li {
  color: #e1e1e1;
}

.line {
  display: flex;
  justify-content: space-between;
}

.line p {
  margin: auto 0 auto 0;
}

.line label {
  margin-left: 25px;
}

.compose-item.selected {
  background-color: #FC9ABA;
  border: 2px solid #FFB3CC; /* Exemple de style quand un item est sélectionné */
}

.compose-item {
  border: 2px solid transparent;
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  background-color: #ffffff75;
  border-radius: 12px;
}

.compose-image-base {
  width: 220px;
}
.btn-compose {
  background-color: #EA4C89;
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 20px;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 10px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.btn-compose:hover {
  background-color: #F082AC;
}
@media screen and (max-width: 768px) {
  .compose-ton-roll-container {
    height: auto;
  }

  .category {
    width: 80%;
    margin-top: 15px;
  }

  .category h1 {
    font-size: 27px;
  }

  .bullet-point ul li {
    font-size: 16px;
  }

  .footer-compose {
    justify-content: space-around;
  }
}