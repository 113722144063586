.cart-page {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: 'Poppins', sans-serif;
}

.cart-category {
    width: 100%;
    color: white;
    z-index: 5;
}

.cart-item-quantity {
    color: black;
}

.cart-title {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 20px;
    color: #fff;
}

.link-to-cart a{
    text-decoration: none;
    color: #007bff;
    font-size: 1.2rem;
    margin-top: 20px;
    display: block;
    text-align: center;
}

.cart-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-end;
}

.cart-mod-item {
    /* width: 100%; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 12px;
    background-color: #ffffff75;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cart-mod-item:hover {
    transform: scale(1.02);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.cart-item-image {
    height: 140px;
    object-fit: cover;
    border-radius: 10px;
}

.cart-item-details {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.cart-item-descript {
    margin: 1rem 1.5rem;
}

.cart-item-title {
    font-size: 1.4rem;
    margin: 0 0 10px 0;
    color: black;
}

.cart-item-price {
    color: black;
    font-weight: bold;
    margin-bottom: 10px;
}

.cart-item-controls {
    display: flex;
    align-items: center;
    gap: 10px;
}

.checkout-button {
    width: 36px;
    height: 36px;
    font-size: 1.1rem;
    background-color: #d60b76;
    color: white;
    border: none;
    border-radius: 100%;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.checkout-button:hover {
    background-color: #8958ce;
}

.cart-submit-button {
    margin: 20px auto;
    padding: 15px;
    font-size: 1.5rem;
    background-color: #d60b76;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.cart-submit-button:hover {
    background-color: #ffd4e5;
    color: black;
}

.empty-cart-message {
    text-align: center;
    font-size: 1.3rem;
    color: #666;
}

.cart-mod-total {
    width: 33%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2.3rem;
    /* margin-top: ; */
    background: linear-gradient(to right, #f7f7f7, #e6e6e6);
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    margin: 1.5rem 0;
}

.cart-mod-total h3 {
    font-size: 1.2rem;
    font-weight: 600;
    color: #333;
    margin: 0;
}

.cart-mod-total p {
    font-size: 1.5rem;
    font-weight: bold;
    color: #000; /* Couleur principale */
    margin: 0;
    transition: color 0.3s ease;
}

.cart-mod-total p:hover {
    color: #a5009f; /* Couleur d'interaction */
}

.cart-item-image {
    width: 40%;
    height: auto;
}

@media (max-width: 868px) {
    .cart-mod-total {
        flex-direction: column;
        text-align: center;
    }

    .cart-mod-total h3 {
        margin-bottom: 0.5rem;
    }
}
