/* Thai.css */
.Thai {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 80px 20px;
    padding-bottom: 30px;
    left:15%;
    width: 66%;
    top: 31px;
    margin-left: 3vw;
}

.container-thai h2 {
    font-size: 3.5rem;
    margin: 0;
    padding: 20px;
    text-align: center;
    color: #d4307a;
    font-weight: 700;
    backdrop-filter: blur(25px);
}

.container-thai {
    border-radius: 8px;
    position: relative;
    padding-bottom: 14px;
    margin: auto;
    overflow-x: hidden;
    overflow-y: scroll;
    height: calc(100vh - 130px);
}

.image-part {
    position: relative;
}

.ThaiItem {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    transition: transform 0.3s ease-in-out;
}

.ThaiItem:hover {
    transform: scale(1.05);
}

.ItemDetails .itemPrice{
font-size: 1rem;
font-weight: bold;
}

.meal-info {
    display: flex;
    justify-content: space-between;
}

.info-meat {
    margin-top: 8px;
}

.info-meat img {
    width: 29px;
    height: 29px;
}

.meat-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 98%;
    align-items: center;
    margin-bottom: 20px;
    padding: 15px;
    background-color: #ffffff75;
    border-radius: 8px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.ItemDetails h3 {
    display: flex;
    letter-spacing: 0.0892857143em;
	font-size: 1.5rem;
    margin: 2px;
    padding: 2px;
    /* color: rgb(160, 76, 175); */
}

.PopUpDetails {
    /* display: flex; */
    text-align: center;
    margin: 2px;
    padding: 2px;
    color: rgb(215, 215, 215);

}

.HoverImage {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.ThaiItem:hover .HoverImage {
    opacity: 1;
}

.ThaiPopup {
    position: fixed;
    top: 0;
    left: 0;
    /* width: 100%;
    height: 100%; */
    backdrop-filter: blur(10px);
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease-in-out;
    display: flex;
    flex-direction: row;
    border-radius: 8px;
}

.ImageContainer {
    position: relative;
    display: flex;
}

.PopupContent {
    width: 60%;
    height: 75%;
    background-color: white;
    border-radius: 8px;
    display: flex;
}

.ThaiPopup .PopupImage {
    overflow: hidden;
    position: relative;
    border-radius: 8px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    width: 100%;
}

@media screen and (max-width: 1000px) {
    .container-thai {
        height: calc(100vh - 80px);
    }
}

@media screen and (max-width: 600px) {
    .container-thai h2 {
        font-size: 1.5rem;
    }

    .ItemDetails .itemPrice{
        font-size: .7rem;
        font-weight: bold;
    }

    .ItemDetails h3 {
        font-size: 1rem;
    }

    .menu-item {
        width: 90%;
        flex-direction: column;
        flex-wrap: nowrap;
    }

    .ItemDetails {
        width: 98%;
    }

    .infobulle {
        width: 150px;
    }
}