/* Sushi.css */

.menu-section {
    border-radius: 8px;
    margin: auto;
    height: calc(100vh - 110px);
    /* box-shadow: -6px 8px 13px 1px rgba(0, 0, 0, 0.1); */
    overflow-y: scroll;
}

.menu-section h2 {
    font-size: 3.5rem;
    margin: 0;
    padding: 20px;
    text-align: center;
    color: #d4307a;
    font-weight: 700;
    backdrop-filter: blur(25px);
}

.menu-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
    padding-top: 9px;
}

.menu-item {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
    width: 38%;
    min-width: 450px;
    padding: 15px;
    background-color: #ffffff75;
    border-radius: 8px;
    z-index: 5;
}

.menu-item:nth-child(even) {
    flex-direction: row-reverse;
}

.compose-details h1 {
    font-size: 2rem;
    margin: 6px;
}

.item-image {
    width: 100%;
    max-height: 227px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 10px;
}

.menu-item h3 {
    font-size: 1.5rem;
    margin-bottom: 8px;
}

.menu-item p {
    font-size: 1.15rem;
    margin-bottom: 6px;
}

.menu-item p:last-child {
    font-weight: bold;
}

.menu-item-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;
    min-width: 40%;
}

.menu-text {
    text-align: center;
}

@media screen and (max-width: 1000px) {
    .menu-text {
        width: 75%;
    }

    .menu-item {
        width: 80%;
    }

    .menu-item-content {
        min-width: 60%;
    }
    
}

@media screen and (max-width: 600px) {
    .menu-item {
        width: 90%;
        flex-direction: column;
        min-width: auto;
    }

    .menu-item:nth-child(even) {
        flex-direction: column;
    }

    .menu-item-content {
        width: 100%;
    }

    .menu-section {
        height: calc(100vh - 80px);
    }

    .menu-section h2 {
        font-size: 1.5rem;
    }

    .menu-item h3 {
        font-size: 1rem;
    }

    .item-image {
        width: 100%;
        max-height: 200px;
        object-fit: cover;
    }
}