.title {
    font-family: cursive;
    font-size: 2.3rem;
    margin-top: 12px;
    padding-bottom: 12px;
    color: #fff;
}

/* Conteneur principal */
.parent {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    width: 75%;
    /* height: 75%; */
    margin: auto;
    background-color: #ffffff75;
    border-radius: 8px;
}

/* Grille des zones */
.div1 {
    grid-area: 1 / 1 / 3 / 3;
}

.div2 {
    grid-area: 1 / 3 / 2 / 4;
}

.div3 {
    grid-area: 1 / 4 / 2 / 5;
}

.div4 {
    grid-area: 2 / 3 / 3 / 4;
}

.div5 {
    grid-area: 2 / 4 / 3 / 5;
}

.div6 {
    grid-area: 3 / 1 / 4 / 2;
}

.div7 {
    grid-area: 3 / 2 / 4 / 3;
}

.div8 {
    grid-area: 3 / 3 / 4 / 4;
}

.div9 {
    grid-area: 3 / 4 / 4 / 5;
}

.MenuItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* Assure que l'image et le texte se placent de chaque côté */
    overflow: hidden;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
    flex-wrap: wrap;
}

/* 
    align-items: center;
    background-color: #f3dbea;
    border-radius: 8px;
    box-shadow: 0 0 10px #0000001a;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: all .3s ease-in-out;
     */

.MenuItem.left {
    flex-direction: row;
    /* Image à gauche, texte à droite */
}

.MenuItem.right {
    flex-direction: row-reverse;
    /* Image à droite, texte à gauche */
}

.MenuItemImage {
    object-fit: cover;
    cursor: pointer;
    border-radius: 8px;
    /* scale: 0.7; */
    width: 100%;
}

.MenuLink {
    z-index: 5;
}

.MenuItemDescription {
    width: 40%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.MenuExempleImage {
    width: 50%;
    object-fit: cover;
    border: 1px solid #d60b76;
}

.MenuItemText {
    /* background-color: #f3dbea; */
    padding: 20px;
    font-size: 1.5rem;
    color: #fff;
    height: 100%;
    border-radius: 8px;
    width: 400px;
    display: flex;
    align-items: center;
    font-family: 'Philosopher-Regular';
}

.TextOverlay {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 12px;
    gap: 12px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.TextOverlay p:not(.heading) {
    font-size: 1.6em;
}

.TextOverlay p:last-child {
    color: #6145e0;
    font-weight: 600;
}

@media screen and (max-width: 1524px) {
    .parent {
        width: 93%;
    }
}

@media screen and (max-width: 768px) {
    .MenuItem.left {
        flex-direction: column;
        /* Image à gauche, texte à droite */
    }

    .MenuItem.right {
        flex-direction: column;
        /* Image à droite, texte à gauche */
    }

    .parent {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        width: 90%;
    }

    .MenuLink {
        margin: 0;
        transform: scale(0.9);
    }

    .div1 { grid-area: 1 / 1 / 3 / 3; }
    .div2 { grid-area: 3 / 1 / 4 / 2; }
    .div3 { grid-area: 3 / 2 / 4 / 3; }
    .div4 { grid-area: 4 / 1 / 5 / 2; }
    .div5 { grid-area: 4 / 2 / 5 / 3; }
    .div6 { grid-area: 5 / 1 / 6 / 2; }
    .div7 { grid-area: 5 / 2 / 6 / 3; }
    .div8 { grid-area: 6 / 1 / 7 / 2; }
    .div9 { grid-area: 6 / 2 / 7 / 3; }
}

@media (max-width: 568px) {
    .parent {
        flex-wrap: wrap;
        justify-content: space-evenly;
        gap: 0px;
    }

    .MenuItem {
        margin-bottom: 12px;
        padding: 0;
    }

    .MenuItemImage {
        width: 75%;
    }

    .title {
        font-size: 1.5rem;
    }

    .TextOverlay p:not(.heading) {
        font-size: 1.2em;
    }

    .TextOverlay p:last-child {
        font-size: 1.2em;
    }
}