:root {
    --light: #f1f1f1;
    --dark: #28292c;
}

.toggle-switch {
    position: relative;
    width: 200px;
}

.slider {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  transition: 0.3s;
}

input:checked ~ .slider {
  background-color: var(--light);
}

.slider::before {
  content: "";
  position: absolute;
  top: 2.2px;
  left: 5px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  box-shadow: inset 8px -0.8px 0px 0px var(--light);
  background-color: var(--dark);
  transition: 0.3s;
}
  
input:checked ~ .slider::before {
  transform: translateX(35px);
  background-color: var(--dark);
  box-shadow: none;
}
