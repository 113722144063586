@font-face {
    font-family: 'Roman-Morrissey-DEMO';
    src:url('/public/static/Roman-Morrissey-DEMO.ttf.woff') format('woff'),
        url('/public/static/Roman-Morrissey-DEMO.ttf.svg#Roman-Morrissey-DEMO') format('svg'),
        url('/public/static/Roman-Morrissey-DEMO.ttf.eot'),
        url('/public/static/Roman-Morrissey-DEMO.ttf.eot?#iefix') format('embedded-opentype'); 
    font-weight: normal;
    font-style: normal;
}

@font-face {
    src:url('/public/static/DancingScript-Bold.ttf') format('truetype'),
        url('/public/static/DancingScript-Medium.ttf') format('truetype'),
        url('/public/static/DancingScript-Regular.ttf') format('truetype');
    font-family: 'Dancing Script';
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Nunito-Black";
    src: url('/public/static/Nunito-Regular.ttf') format('truetype');
}

@font-face {
    src:url('/public/static/osaka-re.ttf') format('truetype');
    font-family: 'osaka re';
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Mathilde';
    src: url('/public/static/mathilde.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ShadowsIntoLight-Regular';
    src: url('/public/static/ShadowsIntoLight-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Philosopher-BoldItalic';
    src: url('/public/static/Philosopher-BoldItalic.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Philosopher-Regular';
    src: url('/public/static/Philosopher-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}
